.missing-price .column-type-price {
  color: red;
  font-weight: bold;
}

.highlight {
  background: transparent !important;
}

.highlight td:not(.menu-cell) {
  background: #eeff00 !important;
}